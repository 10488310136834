<script>
import Btn from '@/components/Form/Btn.vue';
import TextInput from '@/components/Form/TextInput.vue';
import ResizableTable from "@/components/Leentech/ResizableTable.vue";
import UserSelection from "@/components/Form/UserSelection.vue";
import UserSelectionPopup from "@/components/Form/UserSelectionPopup.vue";
import UvedSelectionPopup from "@/components/Form/UvedSelectionPopup.vue";
import ResponsiveTabs from "@/components/Leentech/ResponsiveTabs";
import ResponsiveTab from "@/components/Leentech/ResponsiveTab";
import CommentInput from "@/components/CommentInput.vue";
import User from "@/components/User.vue";
import Users from "@/components/Users.vue";
import SearchResult from "@/components/SearchResult.vue";
import UvedSelection from "@/components/Form/UvedSelection.vue";
import DealSelection from "@/components/Form/DealSelection.vue";
import TaskSelection from "@/components/Form/TaskSelection.vue";

export default {
    name: "Typography",
    components: {
        TaskSelection,
        DealSelection,
        UvedSelection,

        Users,
        User,
        ResponsiveTab,
        ResponsiveTabs,
        Btn,
        // SearchResult,
        // UvedSelection,
        // CommentInput,
        // UserSelection,
        // ResizableTable,
        // UserSelectionPopup,
        // TextInput,
    },

    data() {
        const user = {
            "id": 123,
            "login": "login",
            "uuid": "5aa2d435-766a-4c9d-9bb2-8453f3deb71c",
            "name": "Имя Фамилия",
            "phone": "77774654036",
            "email": "ya@vyacheslav.kz",
            "photo": "http://api.infinityinc-crm.local//storage/admins/avatars/2024/08/05/jS7gpnNDkdsHpRQpFWLjVBlVtgs3hJl8lTbyWfGF.jpg",
            "employee_position": "Должность",
            "role": "Роль",
            "vacation": null,
            "active": true,
            "deleted": false
        };

        return {
            test_user: null,
            test_user2: null,
            test_user3: null,
            test_user4: null,
            userSearchInputValue: null,
            uvedSearchInputValue: null,
            dealSearchInputValue: null,
            taskSearchInputValue: null,
            tab: null,
            comment: `test [user id=1]test[/user] message`,
            columns: [
                {label: 'Name', prop: 'name'},
                {label: 'Age', prop: 'age'},
                {label: 'Email', prop: 'email'}
                // Добавьте другие колонки при необходимости
            ],
            rows: [
                {name: 'John Doe', age: 30, email: 'john@example.com'},
                {name: 'Jane Doe', age: 25, email: 'jane@example.com'}
            ],
            test_deal: null,
            test_task: null,
            test_uved: null,
            errorUserSelect: true,
            activeTab: null,
            userData: user,
            usersData: [
                user,
                {
                    ...user,
                    id: 321,
                    photo: null
                },
                {
                    ...user,
                    id: 322,
                    photo: null
                }
            ]
        }
    },

    created() {
        console.log('Typography created');
    },

    mounted() {
        console.log('Typography mounted');
        this.fetchUsers()
    },

    methods: {
        handleClick() {
        },

        submitComment() {
            alert(this.comment);
        },

        fetchUsers() {
            this.$http.get('admin/admin', {
                params: {
                    fltr: {
                        id: {
                            eq: '52'
                        }
                    }
                }
            })
                .then(response => {
                    this.test_user2 = response.body.data;
                    this.test_user3 = response.body.data[0];
                })
                .catch(error => {
                    console.error(error);
                });
        },
        tabClickTest() {
            alert('test')
        },

        userClick() {
            alert('user')
        }
    }
}
</script>

<template>
    <v-container fluid>
        <PageLayout>
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-text>
                            <h1>Заголовок 1</h1>
                            <h2>Заголовок 2</h2>
                            <h3>Заголовок 3</h3>
                            <h4>Заголовок 4</h4>
                            <h5>Заголовок 5</h5>
                            <h6>Заголовок 6</h6>

                            <p>С другой стороны дальнейшее развитие различных форм деятельности влечет за собой процесс внедрения и модернизации существенных финансовых и административных условий. Равным образом укрепление и развитие структуры влечет за собой процесс внедрения и модернизации системы обучения кадров, соответствует насущным потребностям.</p>
                            <p>Разнообразный и богатый опыт укрепление и развитие структуры позволяет выполнять важные задания по разработке дальнейших направлений развития. Идейные соображения высшего порядка, а также постоянный количественный рост и сфера нашей активности представляет собой интересный эксперимент проверки существенных финансовых и административных условий. Повседневная практика показывает, что сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие в формировании модели развития. Равным образом постоянное информационно-пропагандистское обеспечение нашей деятельности позволяет оценить значение системы обучения кадров, соответствует насущным потребностям. Разнообразный и богатый опыт реализация намеченных плановых заданий способствует подготовки и реализации позиций, занимаемых участниками в отношении поставленных задач. Идейные соображения высшего порядка, а также реализация намеченных плановых заданий играет важную роль в формировании форм развития.</p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

<!--            <v-row>-->
<!--                <v-col>-->
<!--                    <SearchResult-->
<!--                        searchText="19"-->
<!--                    ></SearchResult>-->
<!--                </v-col>-->
<!--            </v-row>-->

            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title>
                            Пользователи
                        </v-card-title>
                        <v-card-text>
                            <div style="display: flex; flex-wrap: wrap; gap: 12px;">
                                <User
                                    :item="userData"
                                    @click="userClick"
                                />
                                <User :item="{
                                    ...userData,
                                    photo: null
                                }"/>
                                <User :item="{
                                    ...userData,
                                    vacation: {
                                        id: 2,
                                        from_date: '2024-07-30',
                                        to_date: '2024-08-26',
                                        type: '\u041e\u0442\u043f\u0443\u0441\u043a',
                                        replacing: {
                                            id: 52,
                                            uuid: '5aa2d435-766a-4c9d-9bb2-8453f3deb71c',
                                            name: '\u0412\u044f\u0447\u0435\u0441\u043b\u0430\u0432 \u0428\u0435\u0432\u0447\u0435\u043d\u043a\u043e',
                                            phone: '77774654036',
                                            active: true,
                                            photo: 'http://api.infinityinc-crm.local//storage/admins/avatars/2024/08/05/jS7gpnNDkdsHpRQpFWLjVBlVtgs3hJl8lTbyWfGF.jpg'
                                        }
                                    }
                                }"/>
                                <Users :users="usersData"/>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-card>
                        <v-card-text>
                            <div class="buttons-block">
                                <Btn @click="handleClick">Кнопка</Btn>
                                <Btn :to="{name: 'uved'}">Ссылка</Btn>
                                <Btn color="secondary">Второй цвет</Btn>
                                <Btn disabled>Не доступная</Btn>
                                <Btn color="red">Цветная</Btn>
                                <Btn>
                                    <template v-slot:prepend>
                                        <v-icon>mdi-check</v-icon>
                                    </template>
                                    Button
                                    <template v-slot:append>
                                        <v-icon color="warning"></v-icon>
                                        <v-icon>mdi-alert</v-icon>
                                    </template>
                                </Btn>
                                <Btn size="large" large>Большая</Btn>
                                <Btn size="large" x-large>Сверх большая</Btn>
                                <Btn small>Маленька</Btn>
                                <Btn x-small>Сверх маленькая</Btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

<!--            <v-row>-->
<!--                <v-col>-->
<!--                    <v-card>-->
<!--                        <v-card-title>Выбор пользователя</v-card-title>-->
<!--                        <v-card-text>-->
<!--                            <v-alert color="yellow lighten-3">Поле ввода в данном примере не является частью компонента выбора пользователя и служит только для поиска пользователя</v-alert>-->
<!--                            <TextInput class="mb-6" id="user-search-input" label="Просто выбор пользователя" v-model="userSearchInputValue" />-->
<!--                            <UserSelectionPopup-->
<!--                                id="test_user_popup"-->
<!--                                v-model="test_user4"-->
<!--                                :search-value="userSearchInputValue"-->
<!--                                :error="true"-->
<!--                                multiple-->
<!--                            />-->
<!--                        </v-card-text>-->
<!--                    </v-card>-->
<!--                </v-col>-->
<!--            </v-row>-->

<!--            <v-row>-->
<!--                <v-col>-->
<!--                    <v-card>-->
<!--                        <v-card-title>-->
<!--                            Выбор пользователя совместно с input-->
<!--                        </v-card-title>-->
<!--                        <v-card-text>-->
<!--                            <UserSelection-->
<!--                                id="test_user"-->
<!--                                label="Множественный"-->
<!--                                v-model="test_user"-->
<!--                                multiple-->
<!--                            />-->

<!--                            <UserSelection-->
<!--                                id="test_user"-->
<!--                                label="Множественный с выбранным значением"-->
<!--                                v-model="test_user2"-->
<!--                                multiple-->
<!--                            />-->

<!--                            <UserSelection-->
<!--                                id="test_user"-->
<!--                                label="Одиночный"-->
<!--                                v-model="test_user3"-->
<!--                            />-->

<!--                            <UserSelection-->
<!--                                id="test_user"-->
<!--                                label="Одиночный c ошибкой"-->
<!--                                v-model="test_user3"-->
<!--                                :error="true"-->
<!--                            />-->
<!--                        </v-card-text>-->
<!--                    </v-card>-->
<!--                </v-col>-->
<!--            </v-row>-->

            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title>Выбор клиента</v-card-title>
                        <v-card-text>
                            <UvedSelection
                                label="Выбор клиента"
                                id="test_uved"
                                v-model="test_uved"
                                :search-value="uvedSearchInputValue"
                                multiple
                            />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title>Выбор процесса</v-card-title>
                        <v-card-text>
                            <DealSelection
                                label="Выбор клиента"
                                id="test_deal"
                                v-model="test_deal"
                                :search-value="dealSearchInputValue"
                                multiple
                            />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title>Выбор задачи</v-card-title>
                        <v-card-text>
                            <TaskSelection
                                label="Выбор задачи"
                                id="test_task"
                                v-model="test_task"
                                :search-value="taskSearchInputValue"
                                multiple
                            />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

<!--            <v-row>-->
<!--                <v-col>-->
<!--                    <v-card>-->
<!--                        <v-card-title>-->
<!--                            Компонент для комментирования чего-либо-->
<!--                        </v-card-title>-->
<!--                        <v-card-text>-->
<!--                            <v-card>-->
<!--                                <CommentInput-->
<!--                                    v-model="comment"-->
<!--                                    @send="submitComment"-->
<!--                                ></CommentInput>-->
<!--                            </v-card>-->
<!--                        </v-card-text>-->
<!--                    </v-card>-->
<!--                </v-col>-->
<!--            </v-row>-->

<!--            <v-row>-->
<!--                <v-col>-->
<!--                    <resizable-table :rows="rows" :columns="columns">-->
<!--                        <template v-slot:date="{ item }">-->
<!--                            <strong>{{ item.name }}</strong>-->
<!--                        </template>-->
<!--                    </resizable-table>-->
<!--                </v-col>-->
<!--            </v-row>-->

            <v-row>
                <v-col>
                    <h2>Адаптивные вкладки</h2>
                    <v-alert>Вкладки которые на телефоне выглядят как аккордеон, а на десктопе как вкладки</v-alert>
                    <span>active tab: {{ activeTab }}</span>
                    <br>
                    <Btn @click="activeTab = 1">go to 2 tab</Btn>
                    <ResponsiveTabs :tabs-per-row="2" @input="activeTab = $event">
                        <ResponsiveTab title="Tab 1">
                            <template v-slot>Content for Tab 1</template>
                            <template v-slot:after-btn>
                                <v-icon small color="secondary" @click="tabClickTest">mdi-plus-circle-outline</v-icon>
                            </template>
                        </ResponsiveTab>
                        <ResponsiveTab title="Tab 2">
                            <template v-slot:before-btn>Before Btn 2</template>
                            <template v-slot:after-btn>After Btn 2</template>
                            <template>Content for Tab 2</template>
                        </ResponsiveTab>
                        <ResponsiveTab title="Tab 3">
                            <template v-slot:before-btn>Before Btn 3</template>
                            <template v-slot:after-btn>After Btn 3</template>
                            <template v-slot:before>Before 3</template>
                            <template v-slot:after>After 3</template>
                            <template>Content for Tab 3</template>
                        </ResponsiveTab>
                        <ResponsiveTab title="Tab with error" :class="{error: true}">
                            <template v-slot:before-btn>Before Btn 3</template>
                            <template v-slot:after-btn>After Btn 3</template>
                            <template>Content for Tab 3</template>
                        </ResponsiveTab>
                        <ResponsiveTab title="Tab 4" v-if="false">
                            <template v-slot:before-btn>Before Btn 4</template>
                            <template v-slot:after-btn>After Btn 4</template>
                            <template v-slot:before>Before 4</template>
                            <template v-slot:after>After 4</template>
                            <template v-slot>Content for Tab 4</template>
                        </ResponsiveTab>
                    </ResponsiveTabs>
                </v-col>
            </v-row>
        </PageLayout>
    </v-container>
</template>

<style scoped lang="scss">
.btns {
    & > * {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}
</style>
